import React, { useState } from 'react';
import styles from './HeaderListItemButtonExpandable.module.scss';

interface IHeaderListItemListExpandable {
  children: React.ReactNode | React.ReactNode[];
  index: number;
  label: string;
  mobile: boolean; // just checks for mobile navigation being open to infer
  name: string;
  open: number | null;
  button: React.ReactNode;
  setOpen: React.Dispatch<React.SetStateAction<number | null>>;
}

export const HeaderListItemButtonExpandable = ({
  children,
  index,
  mobile,
  name,
  open,
  button,
  setOpen,
}: IHeaderListItemListExpandable) => {
  const [state, setState] = useState(false);

  const handleAction = () => {
    setState(!state);
    setOpen(index === open ? null : index);
  };

  const expanded = mobile ? state : index === open;

  return (
    <li
      className={`
          ${styles['menu-item-expandable']}
          ${expanded ? styles.expanded : ''}`}
      role="menuitem"
    >
      <button
        aria-expanded={expanded}
        aria-controls={`section-${name}`}
        aria-label={name}
        id={`id-collapse-${name}`}
        className={`
            ${styles.button}
          `}
        onClick={handleAction}
        onMouseEnter={() => {
          if (mobile) return;
          setOpen(index);
        }}
        type="button"
      >
        {button}
      </button>
      <div className={styles.collapse} aria-labelledby={`id-collapse-${name}`} id={`section-${name}`} role="region">
        <div className={styles.container}>
          <div className={styles.menu}>{children}</div>
        </div>
      </div>
    </li>
  );
};
