import React from 'react';
import { AthenaLogo } from './AthenaLogo';
import styles from './HeaderBrand.module.scss';

type HeaderBrandProps = {
  DynamicLink: any;
  linkProps: object;
};

export const HeaderBrand = ({ DynamicLink = 'a', linkProps }: HeaderBrandProps) => (
  <div className={styles.root}>
    <DynamicLink className={styles.link} {...linkProps}>
      <span className={styles.logo}>
        <AthenaLogo />
      </span>
    </DynamicLink>
  </div>
);
