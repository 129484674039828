import React from 'react';
import styles from './Container.module.scss';
import { ContainerSizes } from '../constants/ContainerSizes';
import { ContainerHorizontalPadding } from '../constants/ContainerHorizontalPadding';

interface IContainer {
  children: React.ReactNode | React.ReactNode[];
  size?: ContainerSizes | string;
  horizontalPadding?: ContainerHorizontalPadding | string;
}

export const Container = ({
  children,
  size = ContainerSizes.LARGE,
  horizontalPadding = ContainerHorizontalPadding.DEFAULT,
}: IContainer) => <div className={`${styles.root} ${styles[horizontalPadding]} ${styles[size]}`}>{children}</div>;
