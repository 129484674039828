import React from 'react';
import styles from './HeaderBackdrop.module.scss';

interface IHeaderBackdrop {
  active: boolean;
}

export const HeaderBackdrop = ({ active }: IHeaderBackdrop) => (
  <div className={`${styles.root} ${active ? styles.active : styles.inactive}`} />
);
