/* eslint-disable no-unused-vars */
export enum HeadingSizes {
  EXTRALARGE = 'xl',
  LARGE = 'l',
  MEDIUM = 'm',
  SMALL_MEDIUM = 'sm',
  SMALL = 's',
  CARD = 'card',
  POWERUP = 'powerup',
  BANNER = 'banner',
  DISCLAIMER = 'disclaimer',
}
