import React, { useEffect, useRef, useState } from 'react';

interface HeaderExpandProps {
  children: React.ReactNode;
  duration?: number;
  expand: boolean;
}

const HeaderExpand = (props: HeaderExpandProps) => {
  const {
    children,
    duration = 100,
    expand = false,
  } = props;
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setHeight(ref.current?.clientHeight || 0);
    const handleResize = () => {
      setHeight(ref.current?.clientHeight || 0);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [expand, setHeight]);

  const transitionHeight = {
    transition: `max-height ${duration}ms ease-in-out ${expand ? 0 : duration}ms`,
  };

  const transitionOpacity = {
    transition: `opacity ${duration}ms ease-in-out ${expand ? duration : 0}ms`,
  };

  return (
    <div
      style={{
        maxHeight: expand ? height : 0,
        overflow: expand ? 'visible' : 'hidden',
        ...transitionHeight,
      }}
    >
      <div style={{ opacity: expand ? 1 : 0, ...transitionOpacity }}>
        <div ref={ref}>{children}</div>
      </div>
    </div>
  );
};

export default HeaderExpand;
