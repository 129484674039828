import React from 'react';
import styles from './HeaderListCohortListExpandableListItem.module.scss';
import { Arrow } from './Arrow';

interface IHeaderListCohortListExpandableListItem {
  active: boolean;
  description: string;
  DynamicLink?: any;
  label: string;
  linkProps: object;
}

export const HeaderListCohortListExpandableListItem = ({
  active,
  description,
  DynamicLink = 'a',
  label,
  linkProps,
}: IHeaderListCohortListExpandableListItem) => (
  <li className={`${styles.root} ${active ? styles.active : ''}`} role="menuitem">
    <DynamicLink className={styles.link} {...linkProps}>
      <span className={styles.title}>
        <span className={styles.label}>{label}</span>
        <span className={styles.arrow}>
          <Arrow direction="right" color="currentColor" />
        </span>
      </span>
      <span className={styles.description} aria-hidden="true">
        {description}
      </span>
    </DynamicLink>
  </li>
);
