import React from 'react';
import NextLink from 'next/link';

export const Link = ({
  href,
  children,
  className,
  ...props
}: { href: string; children: React.ReactNode; className?: string; }) => (
  <NextLink href={href} className={className} {...props}>
    {children}
  </NextLink>
);
