import React from 'react';
import styles from './HeaderListCohortListExpandableList.module.scss';
import { Arrow } from './Arrow';

export const HeaderListCohortListExpandableList = ({
  children,
  DynamicLink = 'a',
  label,
  linkProps,
}: {
  children: React.ReactNode;
  DynamicLink: any;
  label: string;
  linkProps?: object;
}) => (
  <div className={styles.root}>
    <DynamicLink className={styles.label} {...linkProps}>
      {label}
      <span className={styles.arrow}>
        <Arrow color="currentColor" direction="right" />
      </span>
    </DynamicLink>
    <ul className={styles.list}>{children}</ul>
  </div>
);
