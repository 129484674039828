import React from 'react';
import styles from './HeaderToggle.module.scss';

export const HeaderToggle = ({
  active,
  mobileButton,
  mobileTalkToUsButton,
  setActive,
  contactMenu,
  setContactMenu,
}: {
  active: boolean;
  mobileButton: React.ReactNode;
  mobileTalkToUsButton: React.ReactNode;
  setActive: (active: boolean) => void;
  contactMenu: boolean;
  setContactMenu: (contactMenu: boolean) => void;
}) => (
  /* Disable other items when contact menu is visible */
  <div className={styles.root}>
    {!contactMenu && (
      <>
        <div
          className={`navigation-button ${
            active ? styles['navigation-button-active'] : styles['navigation-button-inactive']
          }`}
        >
          {mobileButton}
        </div>
        <div
          className={`navigation-button ${
            active ? styles['navigation-button-active'] : styles['navigation-button-inactive']
          }`}
        >
          {mobileTalkToUsButton}
        </div>

        <div className={styles['navigation-toggle']}>
          <button
            aria-label="Toggle menu"
            className={`${styles['navigation-toggle-button']} ${
              active ? styles['navigation-toggle-button-active'] : ''
            }`}
            onClick={() => {
              setActive(!active);
            }}
            type="button"
          >
            <span className={styles['navigation-toggle-button-hamburger']}>
              <span className={styles['navigation-toggle-button-hamburger-pickle']} />
              <span className={styles['navigation-toggle-button-hamburger-cheese']} />
              <span className={styles['navigation-toggle-button-hamburger-patty']} />
            </span>
          </button>
        </div>
      </>
    )}

    {contactMenu && (
      <div className={`${styles['close-button']}`}>
        <button type="button" className={styles.button} onClick={() => setContactMenu(false)}>
          <span className={styles.span} />
          <span className={styles.span} />
        </button>
      </div>
    )}
  </div>
);
