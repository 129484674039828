import React from 'react';
import styles from './HeaderListCohortList.module.scss';

export const HeaderListCohortList = ({ label, children }: { label?: string; children: React.ReactNode }) => (
  <li>
    {label && (<span className={styles.label}>{label}</span>)}
    <ul className={styles.root} role="presentation">
      {children}
    </ul>
  </li>
);
