import React from 'react';
import styles from './HeaderFunnelButton.module.scss';

enum ButtonSizes {
  SMALL = 'small',
  LARGE = 'large',
}

export interface IHeaderFunnelButton {
  children?: React.ReactNode | string;
  size?: ButtonSizes | string;
  url?: string;
}

export const HeaderFunnelButton = ({
  children,
  size = ButtonSizes.LARGE,
  url,
}: IHeaderFunnelButton) => (
  <a
    href={url || '/start'}
    className={`${styles.root}  ${styles[size]} ${children === '' ? styles.iconMode : ''} `}
  >
    <span className={styles.text}>{children}</span>
    <span className={styles.pink} />
    <span className={styles.icon} role="presentation">r</span>
  </a>
);
