import React from 'react';
import DOMPurify from 'isomorphic-dompurify';
import styles from './Text.module.scss';
import { Mode } from '../constants/Mode';
import { TextSizes } from '../constants/TextSizes';
import { TextAlignments } from '../constants/TextAlignments';
import { TextMarginBottom } from '../constants/TextMarginBottom';

export interface IText {
  desktopAlignment?: TextAlignments | string;
  mobileAlignment?: TextAlignments | string;
  markdown?: string;
  children?: React.ReactNode;
  marginBottom?: TextMarginBottom | string;
  mode?: Mode | string;
  size?: TextSizes | string;
  style?: React.CSSProperties;
}

export const Text = ({
  desktopAlignment = TextAlignments.LEFT,
  mobileAlignment = TextAlignments.LEFT,
  children,
  markdown,
  mode = Mode.LIGHT,
  marginBottom = TextMarginBottom.MEDIUM,
  size = TextSizes.DEFAULT,
  style = {},
}: IText) => {
  const styleIsNotEmpty = Object.keys(style).length !== 0;
  const sanitizer = DOMPurify.sanitize;
  return (
    <div
      className={`${styles.root} ${styles[size]}  ${styles[`margin-bottom-${marginBottom}`]}
      ${styles[`desktop-${desktopAlignment}`]} ${styles[`mobile-${mobileAlignment}`]}`}
    >
      {markdown && (
        <span
          className={styleIsNotEmpty ? '' : styles[mode]}
          style={style}
          dangerouslySetInnerHTML={{ __html: sanitizer(markdown) }}
        />
      )}
      {children && (
        <span className={styleIsNotEmpty ? '' : styles[mode]} style={style}>
          {children}
        </span>
      )}
    </div>
  );
};
