import React from 'react';
import Script from 'next/script';

export const Schema = (ldJsonSchema: { ldJsonSchema: object | null }) => (
  <>
    <Script id="schema-website" type="application/ld+json">
      {`{
                "@context": "http://schema.org",
                "@type": "WebSite",
                "url": "https://www.athena.com.au",
                "name": "Athena Home Loans"
            }`}
    </Script>
    <Script id="schema-organisation" type="application/ld+json">
      {`{
                "@context": "http://schema.org",
                "@type": "Organization",
                "name": "Athena Home Loans",
                "url": "https://www.athena.com.au",
                "sameAs": [
                "https://www.facebook.com/AthenaHomeLoans",
                "https://twitter.com/athenahomeloans",
                "https://www.instagram.com/athenahomeloans",
                "https://au.linkedin.com/company/athena-financial"
                ],
                "logo": "https://www.athena.com.au/next-assets/images/athena_logo_withoutwordmark.png",
                "contactPoint": [
                {
                    "@type": "ContactPoint",
                    "telephone": "+61 13 35 35",
                    "email": "hello@athena.com.au",
                    "contactType": "customer service",
                    "areaServed": "AU"
                },
                {
                    "@type": "ContactPoint",
                    "telephone": "+61 429 333 555",
                    "contactType": "customer service",
                    "areaServed": "AU"
                }
                ]
            }`}
    </Script>
    {ldJsonSchema && (
      <Script id="schema-custom" type="application/ld+json">
        {`${JSON.stringify(ldJsonSchema)}`}
      </Script>
    )}
  </>
);
