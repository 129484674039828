import React from 'react';
import styles from './Button.module.scss';
import { Mode } from '../constants/Mode';
import { Buttons } from '../constants/Buttons';
import { ButtonSizes } from '../constants/ButtonSizes';
import { ButtonTags } from '../constants/ButtonTags';
import { IconSizes } from '../constants/IconSizes';

export interface IButton {
  args?: any;
  ButtonTag?: ButtonTags;
  children?: React.ReactNode | string;
  icon?: string;
  href?: string;
  iconSize?: IconSizes | string;
  navMode?: boolean;
  mode?: Mode | string;
  size?: ButtonSizes | string;
  type?: Buttons | string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | React.MouseEventHandler<HTMLAnchorElement>;
}

export const Button = ({
  args,
  ButtonTag = ButtonTags.A,
  children,
  icon = 'r',
  href = '#',
  iconSize = IconSizes.SMALL,
  navMode = false,
  mode = Mode.LIGHT,
  onClick,
  size = ButtonSizes.LARGE,
  type = Buttons.SOLID,
}: IButton) => (
  <>
    {ButtonTag === ButtonTags.A && (
      <a
        onClick={onClick}
        href={href || (args?.href ? args.href : '')}
        className={`${styles.root} ${styles[type]} ${styles[mode]} ${styles[size]} ${
          !children || children === '' ? styles['icon-mode'] : ''
        } ${navMode ? styles['nav-mode'] : ''} `}
        {...args}
      >
        {children}
        {icon && <span className={`${styles.icon}  ${styles[iconSize]}`}>{icon}</span>}
      </a>
    )}
    {ButtonTag === ButtonTags.BUTTON && (
      <ButtonTag
        className={`${styles.root} ${styles[type]} ${styles[mode]} ${styles[size]} ${
          !children || children === '' ? styles['icon-mode'] : ''
        } ${navMode ? styles['nav-mode'] : ''} `}
        {...args}
        onClick={onClick}
      >
        {children}
        {icon && <span className={`${styles.icon}  ${styles[iconSize]}`}>{icon}</span>}
      </ButtonTag>
    )}
    {ButtonTag === ButtonTags.SPAN && (
      <ButtonTag
        className={`${styles.root} ${styles[type]} ${styles[mode]} ${styles[size]} ${
          !children || children === '' ? styles['icon-mode'] : ''
        } ${navMode ? styles['nav-mode'] : ''} `}
        {...args}
        onClick={onClick}
      >
        {children}
        {icon && <span className={`${styles.icon}  ${styles[iconSize]}`}>{icon}</span>}
      </ButtonTag>
    )}
  </>
);
