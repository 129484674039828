export const CohortPageRoutes = {
  HOME_OWNERS: {
    REFINANCING: '/home-owners/refinancing',
    BUYING: '/home-owners/buying',
  },
  INVESTORS: {
    REFINANCING: '/investors/refinancing',
    BUYING: '/investors/buying',
  },
} as const;
