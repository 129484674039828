import React from 'react';

interface IArrow {
  direction?: 'right' | 'down' | 'left' | 'up';
  color: string;
}

export const Arrow = ({ color = '#2A282F', direction = 'right' }: IArrow) => {
  const directions = {
    right: 0,
    down: 90,
    left: -180,
    up: -90,
  };

  return (
    <span
      style={{
        display: 'block',
        height: '16px',
        transform: `rotate(${directions[direction]}deg)`,
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" style={{ display: 'block' }}>
        <path
          d="M9.33333 14.527L7.53959 12.7332L11.0122 9.26065H0V6.59398H10.9888L7.53883 3.12216L9.33899 1.33331L15.9122 7.94813L9.33333 14.527Z"
          fill={color}
        />
      </svg>
    </span>
  );
};
