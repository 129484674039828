import { useState, useEffect } from 'react';

export const useResizeAndLocation = (initialState = false): [boolean, any] => {
  const [active, setActive] = useState<boolean>(initialState);

  useEffect(() => {
    document.body.style.overflow = active ? 'hidden' : 'auto';
  }, [active]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1152) {
        setActive(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setActive]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setActive(false);
    }
  }, []); // window.location.pathname dependency is throwing a compile error.

  return [active, setActive];
};

export default useResizeAndLocation;
