export const common = {
  contact: '/contact',
  homehubLogin: '/homehub/home',
  phone: {
    days: 'Weekdays',
    saturday: 'Sat',
    sunday: 'Sun',
    hours: '9AM - 5PM AET',
    href: 'tel:133535',
    string: '13 35 35',
  },
  sms: {
    href: 'sms:+61429333555',
    string: '0429 333 555',
  },
  email: {
    href: 'mailto:hello@athena.com.au',
    string: 'hello@athena.com.au',
  },
};
