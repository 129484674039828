import React from 'react';
import styles from './HeaderListItemListItem.module.scss';

export interface IHeaderListItemListItem {
  active?: boolean;
  DynamicLink: any;
  label?: string;
  linkProps?: object;
  name: string;
}

export const HeaderListItemListItem = ({
  active, DynamicLink, label, linkProps, name,
}: IHeaderListItemListItem) => (
  <li
    className={styles.root}
    role="menuitem"
  >
    <DynamicLink
      id={`id-${name}`}
      className={`
          ${styles.link}
          ${active ? styles.active : ''}
        `}
      {...linkProps}
    >
      {label}
    </DynamicLink>
  </li>
);
