import React, { useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';

import { CohortPageRoutes } from '@/constants/PageRoutes';
import { CallMeNowSnackBar, CalendlyPopup } from '@athenamortgages/nuggets';

import { track } from '@athenamortgages/common-logic';
import styles from './Navigation.module.scss';

import { HeaderBackdrop } from './navigation/components/HeaderBackdrop';
import { HeaderControl } from './navigation/components/HeaderControl';
import { Header } from './navigation/components/Header';
import { HeaderBrand } from './navigation/components/HeaderBrand';
import { HeaderAccessibilityLinks } from './navigation/components/HeaderAccessibilityLinks';
import { HeaderNavigation } from './navigation/components/HeaderNavigation';
import { HeaderToggle } from './navigation/components/HeaderToggle';

import { HeaderList } from './navigation/components/HeaderList';

import { HeaderListCohortList } from './navigation/components/HeaderListCohortList';
import { HeaderListCohortListExpandable } from './navigation/components/HeaderListCohortListExpandable';
import { HeaderListCohortListExpandableList } from './navigation/components/HeaderListCohortListExpandableList';
import { HeaderListCohortListExpandableListItem } from './navigation/components/HeaderListCohortListExpandableListItem';

import { HeaderListItemList } from './navigation/components/HeaderListItemList';
import { HeaderListItemListButtons } from './navigation/components/HeaderListItemListButtons';
import { HeaderListItemListExpandable } from './navigation/components/HeaderListItemListExpandable';
import { HeaderListItemListExpandableList } from './navigation/components/HeaderListItemListExpandableList';
import { HeaderListItemListExpandableListItem } from './navigation/components/HeaderListItemListExpandableListItem';
import { HeaderListItemListItem } from './navigation/components/HeaderListItemListItem';

import { HeaderFunnelButton } from './navigation/components/HeaderFunnelButton';
import { HeaderMobileCallToActions } from './navigation/components/HeaderMobileCallToActions';

import { useResizeAndLocation } from './navigation/hooks/useResizeAndLocation';

import { Link } from './Link';
import { Button } from './Button';

import { ButtonTags } from '../constants/ButtonTags';
import { IconSizes } from '../constants/IconSizes';
import { COHORT, Cohorts } from '../constants/cohorts';
import { Mode } from '../constants/Mode';
import { ButtonSizes } from '../constants/ButtonSizes';
import { HeaderListItemButtonExpandable } from './navigation/components/HeaderListItemButtonExpandable';
import { ContactDetails } from './navigation/components/ContactDetails';
import { TalkToUsButton } from './navigation/components/TalkToUsButton';
import { CallMeMaybe, CallMeMaybePayload, CallMeMaybeTime } from './CallMeMaybe/CallMeMaybe';

const CTA_TEXT = 'Apply' as const;
const CONTACT_TEXT = 'Contact us' as const;

interface INavigation {
  mode: Mode;
  scroll?: boolean;
}

const { INVESTORS, HOME_OWNERS } = Cohorts;

type SnackbarState = {
  open: boolean;
  type: '' | 'success' | 'error';
};

const emptySnackbarState = {
  open: false,
  type: '',
} as SnackbarState;

type PersonalDetailsState = {
  firstName: string;
  mobileNumber: string;
  salesforceId?: string;
};

const emptyPersonalDetails = {
  firstName: '',
  mobileNumber: '',
  salesforceId: '',
};

export const Navigation = ({ mode = Mode.LIGHT, scroll = true }: INavigation) => {
  /* Navigation Menu Controls */
  const [cohort, setCohort] = useState<Cohorts | ''>('');
  const [active, setActive] = useResizeAndLocation();
  const [contactMenu, setContactMenu] = useResizeAndLocation();
  const [open, setOpen] = useState<number | null>(null);
  /* Modal Controls */
  const [openCallMeMaybe, setOpenCallMeMaybe] = useState<boolean>(false);
  const [openCalendlyPopup, setOpenCalendlyPopup] = useState<boolean>(false);
  const [callMeMaybeTime, setCallMeMaybeTime] = useState<CallMeMaybeTime>('now');
  /* Snackbar Controls */
  const [snackbarControls, setSnackbarControls] = useState<SnackbarState>(emptySnackbarState);
  /* Info */
  const [personalDetails, setPersonalDetails] = useState<PersonalDetailsState>(emptyPersonalDetails);

  const pathname = usePathname();

  /* Calendly requires +61 area code to correctly prefill */
  const formatMobileNumber = (mobileNumber: string) => {
    if (mobileNumber && mobileNumber.startsWith('04')) {
      return `+61${mobileNumber.substring(1)}`;
    }

    return mobileNumber;
  };

  async function handleCallMeMaybeSubmit(data: CallMeMaybePayload) {
    const payload = {
      firstName: data.firstName,
      mobileNumber: data.mobileNumber,
      leadSource: data.leadSource,
      brand: 'ATHENA',
    };

    let salesforceId = '';
    try {
      const response = await fetch(`${process.env.NEXT_PUBLIC_LEADS_SERVICE_URL}/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const res = await response.json();
      salesforceId = res.id;
    } catch (e) {
      console.error('Error:', (e as any).message);
    }

    setPersonalDetails({
      salesforceId,
      firstName: data.firstName,
      mobileNumber: data.mobileNumber,
    });

    // Open calendly with sfID param
    if (data.time === 'later') {
      setOpenCallMeMaybe(false);
      setOpenCalendlyPopup(true);
      return;
    }

    setOpenCallMeMaybe(false);
    setSnackbarControls({
      open: true,
      type: salesforceId ? 'success' : 'error',
    });
  }

  function handleContactButtonClick(time: CallMeMaybeTime) {
    setOpenCallMeMaybe(true);
    setCallMeMaybeTime(time);
    setOpen(null);
    setContactMenu(false);

    track({
      event: 'Call me maybe modal opened',
      properties: {
        optionSelected: time === 'now' ? 'Call me now clicked' : 'Call me later clicked',
      },
    });
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && window.localStorage) {
      const storedValue = localStorage.getItem(COHORT) as Cohorts;
      if (storedValue === null) {
        setCohort(HOME_OWNERS);
      }
      if (storedValue === INVESTORS || storedValue === HOME_OWNERS) {
        setCohort(storedValue);
      }
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.localStorage) {
      if (cohort !== '') {
        localStorage.setItem(COHORT, cohort);
      }
    }
  }, [cohort]);

  useEffect(() => {
    setActive(false);
    setOpen(null);
  }, [pathname, setActive]);

  useEffect(() => {
    if (openCallMeMaybe || openCalendlyPopup) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [openCallMeMaybe, openCalendlyPopup]);

  return (
    <>
      <HeaderControl
        active={active}
        mode={mode}
        open={open}
        setOpen={setOpen}
        scroll={scroll}
        contactMenu={contactMenu}
      >
        <Header>
          <HeaderBrand
            DynamicLink={Link}
            linkProps={{
              href: cohort === HOME_OWNERS ? '/' : '/investors',
              'aria-label': 'Athena Home Loans',
              style: { color: 'currentColor' },
            }}
          />

          <HeaderAccessibilityLinks anchor="#skip-to-content" />
          <HeaderToggle
            active={active}
            setActive={setActive}
            contactMenu={contactMenu}
            setContactMenu={setContactMenu}
            mobileTalkToUsButton={
              <TalkToUsButton onClick={() => setContactMenu(!contactMenu)}>{CONTACT_TEXT}</TalkToUsButton>
            }
            mobileButton={<HeaderFunnelButton size={ButtonSizes.SMALL}>{CTA_TEXT}</HeaderFunnelButton>}
          />
          {contactMenu && (
            <div className={styles.collapse}>
              <ContactDetails key={callMeMaybeTime} onContactButtonClick={handleContactButtonClick} />
            </div>
          )}
          <HeaderNavigation active={active}>
            <HeaderList>
              <HeaderListCohortList>
                <HeaderListCohortListExpandable
                  active={cohort === HOME_OWNERS}
                  cohort={HOME_OWNERS}
                  index={0}
                  label="Home owners"
                  name="Home owners dropdown"
                  // eslint-disable-next-line no-nested-ternary
                  open={active ? (cohort === HOME_OWNERS ? 0 : null) : open}
                  setOpen={setOpen}
                  setCohort={setCohort}
                  mobile={active}
                >
                  <HeaderListCohortListExpandableList
                    DynamicLink={Link}
                    label="Options for the home you live in"
                    linkProps={{ href: '/', tabIndex: open === 0 ? 0 : -1, onClick: () => setCohort(HOME_OWNERS) }}
                  >
                    <HeaderListCohortListExpandableListItem
                      label="Refinancing"
                      description="Home owners refinancing"
                      DynamicLink={Link}
                      linkProps={{
                        'aria-label': 'Refinancing',
                        href: CohortPageRoutes.HOME_OWNERS.REFINANCING,
                        onClick: () => {
                          setCohort(HOME_OWNERS);
                        },
                        tabIndex: open === 0 ? 0 : -1,
                      }}
                      active={pathname.startsWith(CohortPageRoutes.HOME_OWNERS.REFINANCING)}
                    />
                    <HeaderListCohortListExpandableListItem
                      label="Buying"
                      description="Home owners buying"
                      DynamicLink={Link}
                      linkProps={{
                        'aria-label': 'Buying',
                        href: CohortPageRoutes.HOME_OWNERS.BUYING,
                        onClick: () => {
                          setCohort(HOME_OWNERS);
                        },
                        tabIndex: open === 0 ? 0 : -1,
                      }}
                      active={pathname.startsWith(CohortPageRoutes.HOME_OWNERS.BUYING)}
                    />
                  </HeaderListCohortListExpandableList>
                </HeaderListCohortListExpandable>
                <HeaderListCohortListExpandable
                  active={cohort === INVESTORS}
                  cohort={INVESTORS}
                  index={1}
                  label="Investors"
                  name="Investors dropdown"
                  // eslint-disable-next-line no-nested-ternary
                  open={active ? (cohort === INVESTORS ? 1 : null) : open}
                  setOpen={setOpen}
                  setCohort={setCohort}
                  mobile={active}
                >
                  <HeaderListCohortListExpandableList
                    DynamicLink={Link}
                    label="Options for your investment"
                    linkProps={{
                      href: '/investors',
                      tabIndex: open === 1 ? 0 : -1,
                      onClick: () => setCohort(INVESTORS),
                    }}
                  >
                    <HeaderListCohortListExpandableListItem
                      label="Refinancing"
                      description="Investors refinancing"
                      DynamicLink={Link}
                      linkProps={{
                        'aria-label': 'Refinancing',
                        href: CohortPageRoutes.INVESTORS.REFINANCING,
                        onClick: () => {
                          setCohort(INVESTORS);
                        },
                        tabIndex: open === 1 ? 0 : -1,
                      }}
                      active={pathname.startsWith(CohortPageRoutes.INVESTORS.REFINANCING)}
                    />
                    <HeaderListCohortListExpandableListItem
                      label="Buying"
                      description="Investors buying"
                      DynamicLink={Link}
                      linkProps={{
                        'aria-label': 'Buying',
                        href: CohortPageRoutes.INVESTORS.BUYING,
                        onClick: () => {
                          setCohort(INVESTORS);
                        },
                        tabIndex: open === 1 ? 0 : -1,
                      }}
                      active={pathname.startsWith(CohortPageRoutes.INVESTORS.BUYING)}
                    />
                  </HeaderListCohortListExpandableList>
                </HeaderListCohortListExpandable>
              </HeaderListCohortList>
              <HeaderListItemList>
                <HeaderListItemListExpandable
                  active={pathname.startsWith('/our-loans')}
                  index={2}
                  label="Our loans"
                  mobile={active}
                  name="Our loans Dropdown"
                  open={open}
                  setOpen={setOpen}
                >
                  <HeaderListItemListExpandableList label="I'm looking for">
                    <HeaderListItemListExpandableListItem
                      active={pathname.startsWith('/products')}
                      linkProps={{ 'aria-label': 'Loan Comparison', href: '/products', tabIndex: open === 2 ? 0 : -1 }}
                      DynamicLink={Link}
                      label="Loan Comparison"
                    />
                    <HeaderListItemListExpandableListItem
                      active={pathname.startsWith('/features/rates-no-fees')}
                      linkProps={{
                        'aria-label': "Athena's Rates",
                        href: '/features/rates-no-fees',
                        tabIndex: open === 2 ? 0 : -1,
                      }}
                      DynamicLink={Link}
                      label="Athena's Rates"
                    />
                    <HeaderListItemListExpandableListItem
                      active={pathname.startsWith('/checklist')}
                      linkProps={{ 'aria-label': 'Checklist', href: '/checklist', tabIndex: open === 2 ? 0 : -1 }}
                      DynamicLink={Link}
                      label="Checklist"
                    />
                    <HeaderListItemListExpandableListItem
                      active={pathname.startsWith('/eligibility')}
                      linkProps={{ 'aria-label': 'Eligibility', href: '/eligibility', tabIndex: open === 2 ? 0 : -1 }}
                      DynamicLink={Link}
                      label="Eligibility"
                    />
                    <HeaderListItemListExpandableListItem
                      active={pathname.startsWith('/features')}
                      linkProps={{ 'aria-label': 'Features', href: '/features', tabIndex: open === 2 ? 0 : -1 }}
                      DynamicLink={Link}
                      label="Features"
                    />
                    <HeaderListItemListExpandableListItem
                      active={pathname.startsWith('/athena-select')}
                      linkProps={{
                        'aria-label': 'Athena Select',
                        href: '/athena-select',
                        tabIndex: open === 2 ? 0 : -1,
                      }}
                      DynamicLink={Link}
                      label="A Home For Every Loan"
                    />
                  </HeaderListItemListExpandableList>
                </HeaderListItemListExpandable>
                <HeaderListItemListExpandable
                  name="Calculators Dropdown"
                  label="Calculators"
                  active={pathname.startsWith('/calculators')}
                  setOpen={setOpen}
                  open={open}
                  index={3}
                  mobile={active}
                >
                  <HeaderListItemListExpandableList label="Crunch the numbers">
                    <HeaderListItemListExpandableListItem
                      active={pathname.startsWith('/calculators/refinancing')}
                      linkProps={{
                        'aria-label': 'Refinance Savings',
                        href: '/calculators/refinancing',
                        tabIndex: open === 3 ? 0 : -1,
                      }}
                      DynamicLink={Link}
                      label="Refinance Savings"
                    />
                    <HeaderListItemListExpandableListItem
                      active={pathname.startsWith('/calculators/buying')}
                      linkProps={{
                        'aria-label': 'Borrowing Power',
                        href: '/calculators/buying',
                        tabIndex: open === 3 ? 0 : -1,
                      }}
                      DynamicLink={Link}
                      label="Borrowing Power"
                    />
                    <HeaderListItemListExpandableListItem
                      active={pathname.startsWith('/calculators/repayment-relief')}
                      linkProps={{
                        'aria-label': 'Repayment Relief',
                        href: '/calculators/repayment-relief',
                        tabIndex: open === 3 ? 0 : -1,
                      }}
                      DynamicLink={Link}
                      label="Repayment Relief"
                    />
                    <HeaderListItemListExpandableListItem
                      active={pathname.startsWith('/calculators/equity')}
                      linkProps={{
                        'aria-label': 'Equity Calculator',
                        href: '/calculators/equity',
                        tabIndex: open === 3 ? 0 : -1,
                      }}
                      DynamicLink={Link}
                      label="Equity Calculator"
                    />
                  </HeaderListItemListExpandableList>
                </HeaderListItemListExpandable>
                <HeaderListItemListItem
                  active={pathname.startsWith('/learn')}
                  DynamicLink={Link}
                  label="Learn"
                  name="Learn"
                  linkProps={{ href: '/learn' }}
                />
                <HeaderListItemListItem
                  active={pathname.startsWith('/about')}
                  DynamicLink={Link}
                  label="About"
                  name="About"
                  linkProps={{ href: '/about' }}
                />
                <HeaderListItemListButtons>
                  <HeaderListItemButtonExpandable
                    name="Contact us dropdown"
                    label="Contact us"
                    setOpen={setOpen}
                    open={open}
                    index={4}
                    mobile={active}
                    button={<TalkToUsButton element="span">{CONTACT_TEXT}</TalkToUsButton>}
                  >
                    <ContactDetails key={callMeMaybeTime} onContactButtonClick={handleContactButtonClick} />
                  </HeaderListItemButtonExpandable>
                  <li className={`${styles['login-button']}`}>
                    <Button
                      ButtonTag={ButtonTags.BUTTON}
                      onClick={() => {
                        window.location.href = '/homehub/home';
                      }}
                      icon="x"
                      iconSize={IconSizes.LARGE}
                      size={ButtonSizes.SMALL}
                      navMode
                    >
                      Log in
                    </Button>
                  </li>
                  <li>
                    <HeaderFunnelButton size={ButtonSizes.SMALL}>{CTA_TEXT}</HeaderFunnelButton>
                  </li>
                </HeaderListItemListButtons>
              </HeaderListItemList>
              <HeaderMobileCallToActions>
                <Button
                  ButtonTag={ButtonTags.BUTTON}
                  icon="x"
                  iconSize={IconSizes.LARGE}
                  size={ButtonSizes.LARGE}
                  onClick={() => {
                    window.location.href = '/homehub/home';
                  }}
                  args={{
                    style: {
                      flex: '1 1 50%',
                      height: '40px',
                      justifyContent: 'center',
                    },
                  }}
                >
                  Login
                </Button>
                <HeaderFunnelButton size={ButtonSizes.LARGE}>{CTA_TEXT}</HeaderFunnelButton>
              </HeaderMobileCallToActions>
            </HeaderList>
          </HeaderNavigation>
        </Header>
      </HeaderControl>
      <HeaderBackdrop active={open === 0 || open === 1} />
      <CallMeMaybe
        open={openCallMeMaybe}
        setOpen={setOpenCallMeMaybe}
        callMeMaybeTime={callMeMaybeTime}
        onSubmit={handleCallMeMaybeSubmit}
      />

      <CallMeNowSnackBar
        firstName={personalDetails.firstName}
        mobileNumber={personalDetails.mobileNumber}
        error={snackbarControls.type === 'error'}
        onClose={() => setSnackbarControls({ ...emptySnackbarState })}
        open={!!(personalDetails.firstName && personalDetails.mobileNumber) && snackbarControls.open}
        key={personalDetails.firstName + personalDetails.mobileNumber + snackbarControls.type}
      />

      <CalendlyPopup
        open={openCalendlyPopup}
        onClose={() => {
          setOpenCalendlyPopup(false);
        }}
        url="https://calendly.com/loan-experts/athena-2025-consultation"
        utm={{
          utmMedium: 'website',
          utmSource: 'primary_nav',
          utmCampaign: 'inbound_routing',
          salesforce_uuid: personalDetails.salesforceId,
        }}
        prefill={{
          name: personalDetails.firstName,
          firstName: personalDetails.firstName,
          customAnswers: {
            a1: formatMobileNumber(personalDetails.mobileNumber),
          },
        }}
      />
    </>
  );
};
