import React from 'react';
import styles from './HeaderListItemListExpandableList.module.scss';

export const HeaderListItemListExpandableList = ({
  children,
  label,
}: {
  children: React.ReactNode;
  label: string;
}) => (
  <div className={styles.root}>
    <span className={styles.label}>{label}</span>
    <ul className={styles.list}>{children}</ul>
  </div>
);
