import React from 'react';
import { CallMeMaybeTime } from '@/components/CallMeMaybe/CallMeMaybe';
import styles from './ContactDetails.module.scss';

const contactDetailsListItems = [
  {
    icon: (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 10C18 8.95 17.79 7.91 17.39 6.94C16.99 5.97 16.4 5.09 15.66 4.34C14.92 3.6 14.04 3.01 13.06 2.61C12.09 2.21 11.05 2 10 2V3.6C10.84 3.6 11.67 3.77 12.45 4.09C13.23 4.41 13.93 4.88 14.53 5.48C15.12 6.07 15.6 6.78 15.92 7.56C16.24 8.34 16.41 9.17 16.41 10.01H18.01L18 10ZM2 8.4001V4.4001C2 3.9601 2.36 3.6001 2.8 3.6001H6.8C7.24 3.6001 7.6 3.9601 7.6 4.4001V7.6001C7.6 8.0401 7.24 8.4001 6.8 8.4001H5.2C5.2 11.9301 8.07 14.8001 11.6 14.8001V13.2001C11.6 12.7601 11.96 12.4001 12.4 12.4001H15.6C16.04 12.4001 16.4 12.7601 16.4 13.2001V17.2001C16.4 17.6401 16.04 18.0001 15.6 18.0001H11.6C6.3 18.0001 2 13.7001 2 8.4001ZM14.8 9.99994C14.8 9.36994 14.67 8.73994 14.43 8.15994C14.19 7.56994 13.84 7.04994 13.39 6.59994C12.94 6.14994 12.41 5.79994 11.83 5.55994C11.24 5.30994 10.62 5.18994 9.98999 5.18994V6.62994C10.44 6.62994 10.87 6.71994 11.28 6.88994C11.69 7.05994 12.06 7.30994 12.37 7.61994C12.68 7.92994 12.93 8.29994 13.1 8.70994C13.27 9.11994 13.36 9.55994 13.36 9.99994H14.8Z"
          fill="#3B015F"
        />
      </svg>
    ),
    title: 'Call us',
    description: (
      <a className={styles.highlight} href="tel:13-35-35">
        13 35 35
      </a>
    ),
  },
  {
    icon: (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.6211 2H5.37109C4.82109 2 4.37109 2.45 4.37109 3V17C4.37109 17.55 4.82109 18 5.37109 18H14.6211C15.1811 18 15.6211 17.55 15.6211 17V3C15.6211 2.45 15.1811 2 14.6211 2ZM10.0011 16.73C9.61109 16.73 9.28109 16.42 9.28109 16.02C9.28109 15.62 9.61109 15.3 10.0011 15.3C10.3911 15.3 10.7111 15.63 10.7111 16.02C10.7111 16.41 10.3911 16.73 10.0011 16.73ZM13.6311 14.2H6.36109V3.99H13.6311V14.2Z"
          fill="#3B015F"
        />
      </svg>
    ),
    title: 'SMS',
    description: (
      <a className={styles.highlight} href="sms:+61429333555">
        0429 333 555
      </a>
    ),
  },
  {
    icon: (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.15 8.87L17.55 4H3.23999L9.64999 8.87C10.09 9.2 10.71 9.2 11.15 8.87ZM18.8 15.7599V4.93994L12.06 10.0599C11.57 10.4299 10.99 10.6199 10.4 10.6199C9.81 10.6199 9.23 10.4299 8.74 10.0599L2 4.93994V15.7599H18.8Z"
          fill="#3B015F"
        />
      </svg>
    ),
    title: 'Email',
    description: (
      <a className={styles.highlight} href="mailto:hello@athena.com.au">
        hello@athena.com.au
      </a>
    ),
  },
  {
    icon: (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.6666 1.66667H3.33329C2.41663 1.66667 1.66663 2.41667 1.66663 3.33333V18.3333L4.99996 15H16.6666C17.5833 15 18.3333 14.25 18.3333 13.3333V3.33333C18.3333 2.41667 17.5833 1.66667 16.6666 1.66667Z"
          fill="#3B015F"
        />
      </svg>
    ),
    title: 'Live chat',
    description: (
      /* Intercom is tied to this ID to open the messenger widget on click */
      <button type="button" role="menuitem" id="id-Contact" className={styles.highlight}>
        Chat to a loan expert
      </button>
    ),
  },
];

type ContactDetailsListItemProps = {
  icon: React.ReactNode;
  title: string;
  description: React.ReactNode;
};

const ContactDetailsListItem = ({ icon, title, description }: ContactDetailsListItemProps) => (
  <li className={styles['contact-item']}>
    <div className={styles.icon}>{icon}</div>
    <h3 className={styles.title}>{title}</h3>
    <div>{description}</div>
  </li>
);

export interface AvatarProps {
  src: HTMLImageElement['src'];
  alt: HTMLImageElement['alt'];
}

export const Avatar = ({ src, alt }: AvatarProps) => <img className={styles.avatar} src={src} alt={alt} />;

export const ContactUsAvatarGroup = () => <img src="/next-assets/images/contact_avatars.png" alt="Loan Experts" />;

export interface BookCallButtonProps {
  children: React.ReactNode;
  href: string;
}

const CallMeNowButton = ({ children, onClick }: { children: React.ReactNode; onClick: () => void }) => (
  <button type="button" onClick={onClick} className={styles['call-me-now-button']}>
    {children}
  </button>
);

const BookCallButton = ({ children, onClick }: { children: React.ReactNode; onClick: () => void }) => (
  <button type="button" onClick={onClick} className={styles['book-call-button']}>
    {children}
  </button>
);

export const ContactDetails = ({
  onContactButtonClick,
}: {
  onContactButtonClick: (time: CallMeMaybeTime) => void,

}) => (
  <section className={styles['contact-details']}>
    <span className={styles.label}>
      Got a question or need help applying? Our Aussie Loan Experts are here to assist.
    </span>
    <span className={styles['call-me-maybe-button-group']}>
      <CallMeNowButton
        onClick={() => onContactButtonClick('now')}
      >
        Call me now
        {' '}
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.6211 2H5.37109C4.82109 2 4.37109 2.45 4.37109 3V17C4.37109 17.55 4.82109 18 5.37109 18H14.6211C15.1811 18 15.6211 17.55 15.6211 17V3C15.6211 2.45 15.1811 2 14.6211 2ZM10.0011 16.73C9.61109 16.73 9.28109 16.42 9.28109 16.02C9.28109 15.62 9.61109 15.3 10.0011 15.3C10.3911 15.3 10.7111 15.63 10.7111 16.02C10.7111 16.41 10.3911 16.73 10.0011 16.73ZM13.6311 14.2H6.36109V3.99H13.6311V14.2Z"
            fill="#ffffff"
          />
        </svg>
      </CallMeNowButton>
      <BookCallButton
        onClick={() => onContactButtonClick('later')}
      >
        Book a call
      </BookCallButton>
    </span>
    <ul className={styles['contact-details-list']}>
      {contactDetailsListItems.map((item) => (
        <ContactDetailsListItem key={item.title} {...item} />
      ))}
      <li className={styles['contact-item-opening-hours']}>
        <div className={styles['contact-days']}>
          <div className={styles['contact-time-and-day']}>
            <span>Weekdays 9am - 5pm AET</span>
          </div>
        </div>
      </li>
    </ul>
  </section>
);
