import React from 'react';

export const AthenaLogo = () => (
  <svg
    width="113"
    height="24"
    viewBox="0 0 113 24"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      display: 'block',
      fill: 'currentColor',
      transition: 'fill 300ms ease-in-out',
    }}
  >
    <mask id="mask0_2091_84461" maskUnits="userSpaceOnUse" x="0" y="0" width="27" height="24">
      <path d="M0 0H26.9725V24H0V0Z" fill="white" />
    </mask>
    <g mask="url(#mask0_2091_84461)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8551 16.1548L16.49 0L13.4482 17.6149L17.0317 24L16.8551 16.1548Z"
        fill="#B40000"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M26.9721 24L16.4893 0L17.0309 24H26.9721Z" fill="#FF0001" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16.4899 0L-0.00976562 24H12.3456L16.4899 0Z" fill="#FF009F" />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.37207 10.3535L17.0313 24.0003H26.9725L9.37207 10.3535Z"
      fill="#B40000"
    />
    <path
      style={{
        fill: 'currentColor',
      }}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.2441 17.2292H38.9071L40.0464 14.6478H44.4258L45.4993 17.2292H47.1889L42.3061 5.93164L37.2441 17.2292ZM42.2792 9.52609L43.7974 13.14H40.6894L42.2792 9.52609Z"
    />
    <path
      style={{
        fill: 'currentColor',
      }}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54.8459 8.1077V17.2304H53.3024V8.1077H50.9502V6.59961H57.1918V8.1077H54.8459Z"
    />
    <path
      style={{
        fill: 'currentColor',
      }}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.2385 10.7578H68.6512V6.59961H70.1954V17.229H68.6512V12.2652H64.2385V17.229H62.6953V6.59961H64.2385V10.7578Z"
    />
    <path
      style={{
        fill: 'currentColor',
      }}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.4152 8.1077H78.3142V10.6619H82.2958V12.1698H78.3142V15.7225H82.4152V17.2304H76.7705V6.59961H82.4152V8.1077Z"
    />
    <path
      style={{
        fill: 'currentColor',
      }}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88.5684 17.2297H90.1117V9.7875L97.5726 17.8771V6.6003H96.0289V13.9873L88.5684 5.87708V17.2297Z"
    />
    <path
      style={{
        fill: 'currentColor',
      }}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M110.237 14.6478H105.857L104.718 17.2292H103.055L108.117 5.93164L112.999 17.2292H111.31L110.237 14.6478ZM109.607 13.14L108.09 9.52609L106.5 13.14H109.607Z"
    />
  </svg>
);
