import React from 'react';
import styles from './HeaderAccessibilityLinks.module.scss';

export const HeaderAccessibilityLinks = ({ anchor }: { anchor: string }) => (
  <section className={styles['accessibility-links']}>
    <h2 className={styles['accessibility-links-title']}>Accessibility links</h2>
    <ul className={styles['accessibility-links-list']}>
      <li className={styles['accessibility-links-list-item']}>
        <a className={styles['accessibility-link']} href={anchor}>
          SKIP TO CONTENT
        </a>
      </li>
    </ul>
  </section>
);
