import React, { useEffect, useRef } from 'react';
import {
  Button,
  cssVar,
  getThemeStyles,
  Link,
  Dialog,
  RadioGroup,
  TextField,
  Typography,
  ArrowForward,
  AUSTRALIAN_MOBILE_REGEX,
  useWindowSize,
  Refresh,
} from '@athenamortgages/nuggets';
import { track } from '@athenamortgages/common-logic';
import styles from './CallMeMaybe.module.scss';

export type CallMeMaybeTime = 'now' | 'later';
export type CallMeMaybePayload = {
  firstName: string;
  leadSource: string;
  mobileNumber: string;
  time: CallMeMaybeTime;
};

export const CallMeMaybe = ({
  open,
  setOpen,
  callMeMaybeTime,
  onSubmit,
}: {
  open: boolean;
  setOpen: (state: boolean) => void;
  callMeMaybeTime: CallMeMaybeTime;
  onSubmit: (data: CallMeMaybePayload) => Promise<void>;
}) => {
  const [isLoading, setLoading] = React.useState(false);
  const themeTokens = getThemeStyles({ activeBrand: 'athena' });
  const formRef = useRef<HTMLFormElement>(null);
  const { isMobile } = useWindowSize();

  const containerStyle: React.CSSProperties = {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  };

  const radioOptions = [
    { value: 'now', label: 'Now', caption: "We'll call you as soon as possible" },
    { value: 'later', label: 'Later', caption: 'Schedule a time for us to call you later' },
  ];

  async function onFormSubmission(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault(); // Prevent the default form submission behavior

    const formData = new FormData(event.currentTarget); // Create a FormData object from the form
    const time = formData.get('time');
    const firstName = formData.get('firstName');
    const mobileNumber = formData.get('mobileNumber');

    const data = {
      firstName,
      mobileNumber,
      time,
      leadSource: time === 'now' ? 'Callback' : 'Calendly',
    } as CallMeMaybePayload;

    track({
      event: 'Call me maybe modal submitted',
      properties: {
        optionSelected: time === 'now' ? 'Call me now submitted' : 'Call me later submitted',
      },
    });

    setLoading(true);
    await onSubmit(data);
    setLoading(false);
    formRef.current?.reset();
  }

  useEffect(() => {
    if (!open) {
      formRef.current?.reset(); // Reset form on close if not submitted
    }
  }, [open]);

  useEffect(() => {
    const form = formRef.current;
    if (!form) return;
    const inputs = Array.from(form.getElementsByTagName('input,button'));
    if (isLoading) {
      inputs.forEach((input) => {
        input.setAttribute('disabled', 'true');
      });
    } else {
      inputs.forEach((input) => {
        input.removeAttribute('disabled');
      });
    }
  }, [isLoading]);

  return (
    <div style={{ ...themeTokens }}>
      <Dialog
        id="dialog-call-me-maybe"
        open={open}
        closeDialog={() => setOpen(false)}
        label="When do you want to talk?"
      >
        <div
          style={{
            backgroundColor: cssVar.grey100,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row-reverse',
            height: '100%',
            padding: 0,
            margin: 0,
            width: '100%',
          }}
        >
          <div
            style={{
              ...containerStyle,
              gap: isMobile ? 24 : 32,
              justifyContent: 'center',
              padding: isMobile ? '48px 32px 32px 32px' : '32px 54px 32px 54px',
            }}
          >
            <img alt="Loan Experts" src="/next-assets/images/NiceToMeetYou.png" width={isMobile ? 230 : 240} />
            <Typography component="h2" variant="h4">
              We&apos;re here to help
            </Typography>
            <Typography variant="body1">One of our Aussie-based Loan Experts will get in touch.</Typography>
          </div>
          <form
            key={callMeMaybeTime}
            id="form-call-me-maybe"
            ref={formRef}
            style={{
              ...containerStyle,
              backgroundColor: 'white',
              gap: isMobile ? 24 : 48,
              padding: isMobile ? 32 : 64,
            }}
            onSubmit={onFormSubmission}
          >
            <TextField label="Your first name" name="firstName" placeholder="e.g. John" required />
            <TextField
              customValidationMessaging={(validityState: ValidityState): string => {
                if (validityState.patternMismatch) {
                  return 'Please enter a valid Australian mobile number beginning with +614 or 04';
                }
                return '';
              }}
              label="Your mobile number"
              name="mobileNumber"
              pattern={AUSTRALIAN_MOBILE_REGEX.toString().replace(/\//g, '')}
              placeholder="04"
              required
            />
            <RadioGroup
              label="When do you want to talk?"
              name="time"
              options={radioOptions}
              defaultValue={callMeMaybeTime}
              key={callMeMaybeTime}
            />
            <Typography variant="caption" style={{ color: cssVar.textSecondary }}>
              By continuing, you agree to receive communications that you can unsubscribe from at any time. Read more in
              our
              {' '}
              <Link
                href="/privacy-policy"
                style={{ color: 'inherit', textDecoration: 'underline', textUnderlineOffset: '3px' }}
              >
                Privacy Policy
              </Link>
              .
            </Typography>
            <div>
              <Button type="submit">
                Next
                {isLoading ? (
                  <Refresh className={styles['call-me-maybe-loading-icon']} size={20} />
                ) : (
                  <ArrowForward size={20} />
                )}
              </Button>
            </div>
          </form>
        </div>
      </Dialog>
    </div>
  );
};
