import React from 'react';
import { Arrow } from './Arrow';

import styles from './HeaderListItemListExpandableListItem.module.scss';

interface IHeaderListItemListExpandableListItem {
  active: boolean;
  DynamicLink?: any;
  label: string;
  linkProps: object;
}

export const HeaderListItemListExpandableListItem = ({
  active, DynamicLink = 'a', label, linkProps,
}: IHeaderListItemListExpandableListItem) => (
  <li className={`${styles.root} ${active ? styles.active : ''}`} role="menuitem">
    <DynamicLink className={styles.link} {...linkProps}>
      <span>{label}</span>
      <span className={styles.arrow}>
        <Arrow color="currentColor" direction="right" />
      </span>
    </DynamicLink>
  </li>
);
