import * as React from 'react';
import styles from './HeaderNavigation.module.scss';

export const HeaderNavigation = ({
  active,
  children,
}: {
  active: boolean | React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
}) => (
  <div className={`${styles['navigation-menu']} ${active ? styles['navigation-menu-active'] : ''}`}>
    <nav aria-label="Athena" className={styles['navigation-menu-nav']} role="navigation">
      <h2 className={styles['navigation-menu-title']} role="presentation">
        Main navigation
      </h2>
      {children}
    </nav>
  </div>
);
