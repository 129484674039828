import React from 'react';
import styles from './TalkToUsButton.module.scss';

export interface ITalkToUsButton {
  children?: React.ReactNode | string;
  element?: 'button' | 'span';
  navMode?: boolean;
  onClick?: () => void;
}

export const TalkToUsButton = ({
  children,
  element = 'button',
  navMode = true,
  onClick = () => {},
}: ITalkToUsButton) => {
  const Element = element;
  return (
    <Element
      type={element === 'button' ? 'button' : undefined}
      onClick={onClick}
      className={`${styles.root}  ${styles.small} ${navMode ? styles['nav-mode'] : ''} `}
    >
      <span className={styles.text}>{children}</span>
      <span className={`${styles.icon}`}>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.3333 10.3333C12.5 10.3333 11.7 10.2 10.9533 9.95333C10.72 9.88 10.46 9.93333 10.2733 10.1133L8.80667 11.58C6.92 10.62 5.37333 9.08 4.41333 7.19333L5.88 5.72C6.06667 5.54 6.12 5.28 6.04667 5.04667C5.8 4.3 5.66667 3.5 5.66667 2.66667C5.66667 2.3 5.36667 2 5 2H2.66667C2.3 2 2 2.3 2 2.66667C2 8.92667 7.07333 14 13.3333 14C13.7 14 14 13.7 14 13.3333V11C14 10.6333 13.7 10.3333 13.3333 10.3333ZM8 2V8.66667L10 6.66667H14V2H8Z"
            fill="white"
          />
        </svg>
      </span>
    </Element>
  );
};
