import Head from 'next/head';
import React from 'react';
import { Schema } from './Schema';

type SEOProps = {
  seoTitle?: string;
  seoOgTitle?: string;
  seoDescription?: string;
  seoOgDescription?: string;
  ogImageUrl?: string;
  ogImageWidth?: string;
  ogImageHeight?: string;
  twitterImage?: string;
  fullPath?: string;
  ldJsonSchema?: object | null;
};

export const SEO = ({
  seoTitle = 'Online Home Loans | Athena',
  seoOgTitle = undefined,
  seoDescription = 'Fast application, no fees, no loyalty tax, low competitive rates for buyers, refinancers & investors. Apply online for your home loan & pay your loan down faster.',
  seoOgDescription = undefined,
  ogImageUrl = 'https://www.athena.com.au/next-assets/images/social/athena-social-1200x630px.jpg',
  ogImageWidth = '1200',
  ogImageHeight = '630',
  fullPath = '',
  ldJsonSchema = null,
  twitterImage = 'https://www.athena.com.au/next-assets/images/social/athena-social-1200x1200px.jpg',
}: SEOProps) => {
  const canonicalUrl = `https://www.athena.com.au${fullPath}`;
  const ogTitle = seoOgTitle || seoTitle;
  const ogDescription = seoOgDescription || seoDescription;
  return (
    <>
      <Head>
        <title key="page-title">{seoTitle}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1,
        shrink-to-fit=no"
        />
        <link rel="canonical" href={canonicalUrl} />
        <meta name="description" content={seoDescription} />
        <meta property="og:url" content={canonicalUrl} />
        <meta name="twitter:url" content={canonicalUrl} />
        <meta property="og:title" content={ogTitle} />
        <meta name="twitter:title" content={ogTitle} />
        <meta property="og:description" content={ogDescription} />
        <meta name="twitter:description" content={ogDescription} />
        <meta property="fb:app_id" content="571988639986488" />
        <meta name="keywords" content="athena, home loans, finance, mortgage" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={ogImageUrl} />
        <meta property="og:image:width" content={ogImageWidth} />
        <meta property="og:image:height" content={ogImageHeight} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@AthenaHomeLoans" />
        <meta name="twitter:image" content={twitterImage} />
      </Head>
      <Schema ldJsonSchema={ldJsonSchema} />
    </>
  );
};
