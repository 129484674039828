import React from 'react';

interface IArrowDropDown {
  color?: string;
}

export const ArrowDropDown = (props: IArrowDropDown) => {
  const { color = 'currentColor' } = props;
  return (
    <span
      style={{
        display: 'block',
        height: '24px',
      }}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.00043 10L12.0004 15L17.0004 10H7.00043Z" fill={color} />
      </svg>
    </span>
  );
};
