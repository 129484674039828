import React, { useEffect } from 'react';
import { Mode } from '../constants/Mode';
import { useScrollDirection } from '../hooks/useScrollDirection';
import styles from './HeaderControl.module.scss';

interface IHeaderControl {
  active: boolean | React.Dispatch<React.SetStateAction<boolean>>;
  open: number | null;
  children: React.ReactNode | React.ReactNode[];
  mode: Mode;
  setOpen: React.Dispatch<React.SetStateAction<number | null>>;
  scroll: boolean;
  contactMenu: boolean;
}

export const HeaderControl = ({
  contactMenu, active, children, open, mode, setOpen, scroll,
}: IHeaderControl) => {
  const scrollDirection = useScrollDirection();

  useEffect(() => {
    const nav: HTMLElement | null = document.querySelector('#main-nav');
    if (nav === null) return;
    nav.setAttribute('data-direction', 'top');
  }, []);

  useEffect(() => {
    const nav: HTMLElement | null = document.querySelector('#main-nav');
    if (nav === null) return;
    if (mode === Mode.DARK) {
      nav.setAttribute('data-visual-mode', 'dark');
      nav.setAttribute('data-current-bg', 'dark');
    } else if (mode === Mode.LIGHT) {
      nav.setAttribute('data-visual-mode', 'light');
      nav.setAttribute('data-current-bg', 'light');
    }
    if (open === 0 || open === 1) {
      nav.setAttribute('data-visual-mode', 'light');
      nav.setAttribute('data-current-bg', 'light');
    }
  }, [mode, open]);

  const handleScroll = () => {
    const nav: HTMLElement | null = document.querySelector('#main-nav');
    if (nav === null) return;
    const scrollY = window.pageYOffset;
    if (scrollY <= 0 && nav.getAttribute('direction') !== 'top') {
      nav.setAttribute('data-direction', 'top');
      return;
    }
    if (!scroll) {
      nav.setAttribute('data-direction', 'down');
      return;
    }
    if (scrollY > 256 && scrollDirection === 'down' && nav.getAttribute('direction') !== 'down') {
      nav.setAttribute('data-direction', 'down');
    } else if (
      scrollY > 64
      && scrollY < 256
      && scrollDirection === 'down'
      && nav.getAttribute('direction') !== 'down-close-to-top'
    ) {
      nav.setAttribute('data-direction', 'down-close-to-top');
    } else if (scrollY > 64 && scrollDirection === 'up' && nav.getAttribute('direction') !== 'up') {
      nav.setAttribute('data-direction', 'up');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <div
      className={`${styles.root} ${open !== null ? styles.open : styles.closed} ${
        active ? styles.active : styles.inactive
      } ${contactMenu && styles.contactOpen}`}
      id="main-nav"
      onMouseLeave={() => {
        if (!active) {
          setOpen(null);
        }
      }}
    >
      {children}
    </div>
  );
};
