import React from 'react';
import styles from './HeaderMobileCallToActions.module.scss';

export const HeaderMobileCallToActions = ({ children }: { children: React.ReactNode }) => (
  <li className={styles.root}>
    <ul className={styles.list} role="presentation">
      {children}
    </ul>
  </li>
);
