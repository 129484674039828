import * as React from 'react';
import styles from './Header.module.scss';

export const Header = ({ children }: { children: React.ReactNode }) => (
  <div className={styles['navigation-header-wrapper']}>
    <header className={styles['navigation-header']} aria-label="Athena">
      {children}
    </header>
  </div>
);
