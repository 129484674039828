import React from 'react';
import { useRouter } from 'next/router';
import { Cohorts } from '@/constants/cohorts';
import styles from './HeaderListCohortListExpandable.module.scss';

interface IHeaderListCohortListExpandable {
  active: boolean;
  children: React.ReactNode | React.ReactNode[];
  cohort: Cohorts | '';
  index: number;
  label: string;
  mobile: boolean; // just checks for mobile navigation being open to infer
  name: string;
  open: number | null;
  setOpen: React.Dispatch<React.SetStateAction<number | null>>;
  setCohort: React.Dispatch<React.SetStateAction<Cohorts | ''>>;
}

export const HeaderListCohortListExpandable = ({
  active,
  children,
  cohort,
  index,
  label,
  name,
  open,
  setOpen,
  setCohort,
  mobile,
}: IHeaderListCohortListExpandable) => {
  const router = useRouter();
  return (
    <li
      className={`
          ${styles['menu-item-expandable']}
          ${index === open ? styles.expanded : ''}`}
      role="menuitem"
    >
      <button
        aria-expanded={index === open}
        aria-controls={`section-${name}`}
        aria-label={name}
        id={`id-collapse-${name}`}
        className={`${styles.button} ${active ? styles.active : ''}`}
        onClick={() => {
          setCohort(cohort);
          if (!mobile) {
            router.push(cohort === Cohorts.HOME_OWNERS ? '/' : '/investors');
          }
          setOpen(index);
        }}
        onFocus={() => {
          setOpen(index);
        }}
        onMouseEnter={() => {
          if (mobile) return;
          setOpen(index);
        }}
        type="button"
      >
        {label}
      </button>
      <div aria-labelledby={`id-collapse-${name}`} className={styles.collapse} id={`section-${name}`} role="region">
        <div className={styles.container}>
          <div className={styles.menu}>{children}</div>
        </div>
      </div>
    </li>
  );
};
