import React from 'react';
import styles from './HeaderListItemList.module.scss';

export const HeaderListItemList = ({ children }: { label?: string; children: React.ReactNode }) => (
  <li>
    <ul className={styles.root} role="presentation">
      {children}
    </ul>
  </li>
);
