import React from 'react';
import styles from './HeaderList.module.scss';

export const HeaderList = ({
  children,
  ...rest
}: {
  children: React.ReactNode;
}) => (
  <ul className={styles.root} {...rest} role="menu">
    {children}
  </ul>
);
