import React from 'react';
import DOMPurify from 'isomorphic-dompurify';
import styles from './Title.module.scss';
import { Mode } from '../constants/Mode';
import { HeadingSizes } from '../constants/HeadingSizes';
import { TextAlignments } from '../constants/TextAlignments';
import { TextMarginBottom } from '../constants/TextMarginBottom';

export interface ITitle {
  desktopAlignment?: TextAlignments | string;
  mobileAlignment?: TextAlignments | string;
  markdown?: string;
  children?: React.ReactNode;
  isBold?: boolean;
  isBanner?: boolean;
  marginBottom?: TextMarginBottom | string;
  mode?: Mode | string;
  size?: HeadingSizes | string;
  style?: React.CSSProperties;
}

export const Title = ({
  desktopAlignment = TextAlignments.LEFT,
  mobileAlignment = TextAlignments.LEFT,
  markdown,
  children,
  isBold = true,
  marginBottom = TextMarginBottom.MEDIUM,
  mode = Mode.LIGHT,
  size = HeadingSizes.EXTRALARGE,
  style = {},
  isBanner = false,
}: ITitle) => {
  const styleIsNotEmpty = Object.keys(style).length !== 0;
  const sanitizer = DOMPurify.sanitize;

  return (
    <div
      className={`${styles.root}
        ${isBold ? styles.bold : ''}
        ${styles[size]}
        ${isBanner ? styles.banner : ''}
        ${styles[`margin-bottom-${marginBottom}`]}
        ${styles[`desktop-${desktopAlignment}`]}
        ${styles[`mobile-${mobileAlignment}`]}`}
    >
      {markdown && (
        <span
          className={styleIsNotEmpty ? '' : styles[mode]}
          style={style}
          dangerouslySetInnerHTML={{ __html: sanitizer(markdown) }}
        />
      )}
      {children && (
        <span className={styleIsNotEmpty ? '' : styles[mode]} style={style}>
          {children}
        </span>
      )}
    </div>
  );
};
